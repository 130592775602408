import Vue from 'vue'
import Router from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer');

// Views - Core
const Dashboard = () => import('@/views/core/Dashboard');
const Environments = () => import('@/views/core/Environments');
const Page404 = () => import('@/views/core/Page404');
const Page500 = () => import('@/views/core/Page500');
const Login = () => import('@/views/core/Login');

// Views - Users
const Players = () => import('@/views/users/Players');
const Player = () => import('@/views/users/Player');
const Characters = () => import('@/views/users/Characters');
const Character = () => import('@/views/users/Character');

// Views - Embers
const Worlds = () => import('@/views/embers/Worlds');
const World = () => import('@/views/embers/World');
const Zones = () => import('@/views/embers/Zones');
const Zone = () => import('@/views/embers/Zone');

//Views - GM
const Accounts = () => import('@/views/gm/Accounts');
const Account = () => import('@/views/gm/Account');
const GameConfigs = () => import('@/views/gm/GameConfigs');
const GameVersions = () => import('@/views/gm/GameVersions.vue');
const PatchVersions = () => import('@/views/gm/PatchVersions.vue');
const Flags = () => import('@/views/gm/Flags');
const Products = () => import('@/views/gm/Products');

//Views - Reports
const Sales = () => import('@/views/reports/Sales');
const Surveys = () => import('@/views/reports/Surveys');
const Subscriptions = () => import('@/views/reports/Subscriptions');

Vue.use(Router);

let router = new Router({
  mode: 'hash',
  linkActiveClass: 'active',
  //scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem('jwt') == null) {
      next({
        path: '/login',
        params: { nextUrl: to.fullPath }
      })
    } else {
      const a = localStorage.jwt.split('.');
      const j = atob(a[1]);
      const data = JSON.parse(j);
      const n = Math.round(+new Date() / 1000);

      if (n > data.exp) {
        localStorage.removeItem('username');
        localStorage.removeItem('roles');
        localStorage.removeItem('jwt');
        next({
          path: '/login',
          params: { nextUrl: to.fullPath }
        })
      } else {
        next()
      }
    }
  } else {
    next()
  }
});

export default router

function configRoutes() {
  return [
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Home',
      component: TheContainer,
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard
        },
        {
          path: 'environments',
          name: 'Environments',
          component: Environments
        },
        {
          path: 'users',
          redirect: '/users/players',
          name: 'Users',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'players',
              name: 'Players',
              component: Players
            },
            {
              path: 'player/:id',
              name: 'Player',
              props: true,
              component: Player
            },
            {
              path: 'characters',
              name: 'Characters',
              component: Characters
            },
            {
              path: 'character/:id',
              name: 'Character',
              props: true,
              component: Character
            }
          ]
        },
        {
          path: 'embers',
          redirect: '/embers/worlds',
          name: 'Embers',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'worlds',
              name: 'Worlds',
              component: Worlds
            },
            {
              path: 'world/:id',
              name: 'World',
              props: true,
              component: World
            },
            {
              path: 'zones',
              name: 'Zones',
              component: Zones
            },
            {
              path: 'zone/:id',
              name: 'Zone',
              props: true,
              component: Zone
            }
          ]
        },
        {
          path: 'gm',
          redirect: '/gm/accounts',
          name: 'GM',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'accounts',
              name: 'Accounts',
              component: Accounts
            },
            {
              path: 'account/:id',
              name: 'Account',
              props: true,
              component: Account
            },
            {
              path: 'products',
              name: 'Products',
              component: Products
            },
            {
              path: 'configs',
              name: 'Game Configs',
              component: GameConfigs
            },
            {
              path: 'versions/client',
              name: 'Game Versions',
              component: GameVersions
            },
            {
              path: 'versions/patcher',
              name: 'Patch Versions',
              component: PatchVersions
            },
            {
              path: 'flags',
              name: 'Game Flags',
              component: Flags
            }
          ]
        },
        {
          path: 'reports',
          redirect: '/reports/sales',
          name: 'Reports',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'subscriptions',
              name: 'Subscriptions',
              component: Subscriptions
            },
            {
              path: 'sales',
              name: 'Sales',
              component: Sales
            },
            {
              path: 'surveys',
              name: 'Surveys',
              component: Surveys
            }
          ]
        }
      ]
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/pages',
      redirect: '/pages/404',
      name: 'Pages',
      component: {
        render(c) { return c('router-view') }
      },
      meta: {
        requiresAuth: false
      },
      children: [
        {
          path: '404',
          name: 'Page404',
          component: Page404
        },
        {
          path: '500',
          name: 'Page500',
          component: Page500
        }
      ]
    }
  ]
}

