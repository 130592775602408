import {
  cibFacebook,
  cibTwitter,
  cibLinkedin,
  cibFlickr,
  cibTumblr,
  cibXing,
  cibGithub,
  cibStackOverflow,
  cibYoutube,
  cibDribbble,
  cibInstagram,
  cibPinterest,
  cibVk,
  cibYahoo,
  cibBehance,
  cibReddit,
  cibVimeo,
  cibCcMastercard,
  cibCcVisa,
  cibStripe,
  cibPaypal,
  cibGooglePay, 
  cibCcAmex,
  cibTwitch
} from '@coreui/icons'
import {
  cifUs,
  cifBr,
  cifIn,
  cifFr,
  cifEs,
  cifPl
} from '@coreui/icons'
import {
  cilAddressBook,
  cilArrowRight,
  cilBan,
  cilBasket,
  cilBell,
  cilCalculator,
  cilCalendar,
  cilCart,
  cilCasino,
  cilCloudDownload,
  cilChartPie,
  cilCheck,
  cilChevronBottom,
  cilChevronTop,
  cilCheckCircle,
  cilClock,
  cilCode,
  cilCommentSquare,
  cilCursor,
  cilDrop,
  cilDollar,
  cilEthernet,
  cilEnvelopeClosed,
  cilEnvelopeOpen,
  cilEuro,
  cilGamepad,
  cilGlobeAlt,
  cilGrid,
  cilFile,
  cilFlagAlt,
  cilJustifyCenter,
  cilLaptop,
  cilLayers,
  cilLightbulb,
  cilList,
  cilLocationPin,
  cilLockLocked,
  cilMagnifyingGlass,
  cilMoon,
  cilMoney,
  cilOptions,
  cilPencil,
  cilPeople,
  cilPuzzle,
  cilSchool,
  cilSettings,
  cilShieldAlt,
  cilSitemap,
  cilSpeech,
  cilSpeedometer,
  cilStar,
  cilTags,
  cilTask,
  cilThumbUp,
  cilToilet,
  cilTerrain,
  cilTrash,
  cilUser,
  cilUserFemale,
  cilUserFollow,
  cilXCircle,
  cilShortText,
  cilFire,
  cilGift

} from '@coreui/icons'

export const iconsSet = Object.assign(
  {},
  {
    cilAddressBook,
    cilArrowRight,
    cilBan,
    cilBasket,
    cilBell,
    cilCalculator,
    cilCalendar,
    cilCart,
    cilCasino,
    cilCloudDownload,
    cilChartPie,
    cilCheck,
    cilChevronBottom,
    cilChevronTop,
    cilCheckCircle,
    cilCode,
    cilClock,
    cilCommentSquare,
    cilCursor,
    cilDrop,
    cilDollar,
    cilEthernet,
    cilEnvelopeClosed,
    cilEnvelopeOpen,
    cilEuro,
    cilGamepad,
    cilGlobeAlt,
    cilGrid,
    cilFile,
    cilFlagAlt,
    cilJustifyCenter,
    cilLaptop,
    cilLayers,
    cilLightbulb,
    cilList,
    cilLocationPin,
    cilLockLocked,
    cilMagnifyingGlass,
    cilMoon,
    cilMoney,
    cilOptions,
    cilPencil,
    cilPeople,
    cilPuzzle,
    cilSchool,
    cilSettings,
    cilShieldAlt,
    cilSpeech,
    cilSpeedometer,
    cilSitemap,
    cilStar,
    cilTags,
    cilTask,
    cilThumbUp,
    cilTerrain,
    cilTrash,
    cilToilet,
    cilUser,
    cilUserFemale,
    cilUserFollow,
    cilXCircle,
    cilShortText,
    cilFire,
    cilGift
  },
  {
    cifUs,
    cifBr,
    cifIn,
    cifFr,
    cifEs,
    cifPl
  },
  {
    cibFacebook,
    cibTwitter,
    cibLinkedin,
    cibFlickr,
    cibTumblr,
    cibXing,
    cibGithub,
    cibStackOverflow,
    cibYoutube,
    cibDribbble,
    cibInstagram,
    cibPinterest,
    cibVk,
    cibYahoo,
    cibBehance,
    cibReddit,
    cibVimeo,
    cibCcMastercard, 
    cibCcVisa, 
    cibStripe, 
    cibPaypal, 
    cibGooglePay,
    cibCcAmex,
    cibTwitch
  }
)
