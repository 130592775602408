import Vue from 'vue'
import App from './App'
import VueResource from 'vue-resource';
import router from './router'
import CoreuiVue from '@coreui/vue'
import moment from 'moment'
import { iconsSet as icons } from './assets/icons/icons.js'

Vue.config.performance = true;
Vue.use(CoreuiVue);
Vue.use(VueResource);

Vue.filter('formatDate', function (value) {
  if (value) {
    return moment(String(value)).format('MM/DD/YYYY hh:mm a')
  }
});

Vue.filter('formatTimeSpan', function (value) {
  if (value) {
    const t = value.split(".");

    if (t.length > 2) {
      let x = t[1].split(":"); 
      return t[0] + " days " + x[0] + " hours " + x[1] + "  minutes";
    } else {
      let x = t[0].split(":"); 
      return x[0] + " hours " + x[1] + " minutes";
    }
  }
});

Vue.filter('monthOnlyDate', function (value) {
  if (value) {
    return moment(String(value)).format('MM/DD/YYYY')
  }
});

Vue.filter('roundthree', function (value) {
  if (value) {
    return (Math.round((value * Math.pow(10, 3)) + (0.0001)) / Math.pow(10, 3)).toFixed(3);
  }
});

Vue.filter('mapmastery', function (value) {
  if (value) {
    if ((value in masteries))
      return masteries[value];
    else
      return value;
  }
});

Vue.filter('currency', function (value) {
  if (value) {
    var g = Math.floor(value / 10000);
    var s = Math.floor((value % 10000) / 100);
    var c = ((value % 10000) % 100);

    return "" + g + "g " + s + "s " + c + "c";
  } else {
    return "0g 0s 0c";
  }
});

const config = {
  zc: {},
  gmapi: "https://api.stormhavenstudios.com", // "http://localhost:5000",
  gameapi: "https://game-api.stormhavenstudios.com",
  flagTxt: function (flags) {
    if ((flags & 1) == 0) {
      return 'Offline';
    }

    if (flags == 1) {
      return 'Open';
    }

    return 'Restricted';
  },
  flagClr: function (flags) {
    if ((flags & 1) == 0) {
      return 'light';
    }

    if (flags == 1) {
      return 'success';
    }

    return 'warning';
  },
  statusTxt: function (active) {
    return active ? "Online" : "Offline";
  },
  statusClr: function (active) {
    return active ? "success" : "light"
  },
  userTxt: function (flags) {
    return (flags & 1) == 0 ? "Disabled" :
      (flags & 2) == 2 ? "GM" :
        (flags & 4) == 4 ? "Dev+" :
          (flags & 8) == 8 ? "Streamer" :
            flags & 1 ? "Active" :
              "Unknown";
  },
  userClr: function (flags) {
    return (flags & 1) == 0 ? "dark" :
      (flags & 2) == 2 ? "info" :
        (flags & 4) == 4 ? "warning" :
          (flags & 8) == 8 ? "secondary" :
            flags & 1 ? "primary" :
              "danger";
  },
  subTxt: function (stat) {
    return !stat ? "NA" :
      stat == "TOP" ? "Active" :
        stat == "CAN" ? "Canceled" :
          "InActive";
  },
  subClr: function (stat) {
    return !stat ? "dark" :
      stat == "TOP" ? "primary" :
        stat == "CAN" ? "danger" :
          "info";
  },
  getHeaders: function () {
    return {
      'Content-Type': 'application/json;charset=utf-8',
      'Authorization': 'Bearer ' + localStorage.jwt
    };
  }
};

config.zc['dev'] = "https://emb-01.embersadrift.com:2091";
config.zc['qa'] = "https://emb-03.embersadrift.com:2090";
config.zc['live'] = "https://emb-02.embersadrift.com:2090";

config.install = function () {
  Object.defineProperty(Vue.prototype, '$config', {
    get() { return config }
  })
}

Vue.use(config);

new Vue({
  el: '#app',
  router,
  icons,
  template: '<App/>',
  components: {
    App
  },
})
